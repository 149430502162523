import Image from 'next/image'

import dynamic from 'next/dynamic'
import apteraImg from '../../public/assets/images/aptera.jpg'
import mvAgustaImg from '../../public/assets/images/mv_agusta_f3rr.jpg'
import wingtraImg from '../../public/assets/images/wingtraone-gen2.jpg'
import Link from 'next/link'
import { faAngleRight as falAngleRight } from '@fortawesome/pro-light-svg-icons/faAngleRight'

const FontAwesomeIcon = dynamic(() =>
  import('@fortawesome/react-fontawesome').then((fa) => fa.FontAwesomeIcon)
)

export default function Testimonials() {
  return (
    <section className="testimonials py-4 py-lg-5">
      <div className="container py-lg-5">
        <h2 className="title">
          <small>Testimonials</small> Approved by your peers
        </h2>
        <div className="row row-cols-1 row-cols-lg-3 pt-lg-5">
          <div className="col mb-3 mb-lg-0">
            <div className="card h-100">
              <div className="card-img-top">
                <Image
                  src={apteraImg}
                  width="416"
                  height="216"
                  className="border-radius-top"
                  alt="Aptera"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </div>
              <div className="card-body">
                <h3 className="card-title pb-3">
                  <small className="pb-5">
                    <a
                      href="https://aptera.us"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Aptera
                    </a>
                  </small>{' '}
                  "AirShaper helped us keep expenses low while still getting
                  amazing CFD work done FAST."
                </h3>
              </div>
              <div className="card-footer text-center bg-none">
                Chris Anthony, Co-CEO of Aptera
              </div>
            </div>
          </div>
          <div className="col mb-3 mb-lg-0">
            <div className="card h-100">
              <div className="card-img-top">
                <Image
                  src={wingtraImg}
                  width="416"
                  height="216"
                  className="border-radius-top"
                  alt="Wingrra"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </div>
              <div className="card-body">
                <h3 className="card-title pb-3">
                  <small className="pb-5">
                    <a
                      href="https://wingtra.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Wingtra
                    </a>
                  </small>{' '}
                  "With Airshaper we could vastly increase our frequency of
                  iterations. We ran more than 100 simulations per month per
                  person and pretty much no onboarding to the tool was needed!"
                </h3>
              </div>
              <div className="card-footer text-center bg-none">
                Constantin Jung, Team Lead Mechanical Engineering & Aerodynamics
              </div>
            </div>
          </div>
          <div className="col mb-3 mb-lg-0">
            <div className="card h-100">
              <div className="card-img-top">
                <Image
                  src={mvAgustaImg}
                  width="416"
                  height="216"
                  className="border-radius-top"
                  alt="MV Agusta"
                  style={{
                    maxWidth: '100%',
                    height: 'auto',
                  }}
                />
              </div>
              <div className="card-body">
                <h3 className="card-title pb-3">
                  <small className="pb-5">
                    <a
                      href="https://www.mvagusta.com/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      MV Agusta
                    </a>
                  </small>{' '}
                  "With AirShaper allowed us to take Aerodynamics Performance to
                  the next level. The wingles on the F3 RR where optimized for
                  maximum downforce using their shape optimization software!"
                </h3>
              </div>
              <div className="card-footer text-center bg-none">
                Brian Gillen, R&D Director of MV Agusta
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 text-center">
          <Link
            passHref
            href="/testimonials"
            className="btn btn-lg btn-primary"
          >
            See What Our Customers Say <FontAwesomeIcon icon={falAngleRight} />
          </Link>
        </div>
      </div>
    </section>
  )
}
